import GtmService from '../../services/gtm'
import OldTagManager from '../../domain/OldTagManager'
import { useEffect, useState } from 'react'

export default function useVirtualPageView(initListener) {
  const [dependencies, setDependencies] = useState(initListener)

  const handleVirtualPageViewGta = () => {
    if (!dependencies) {
      return null
    }

    if (!window.location.href && !document.title){
      return null
    }

    const gtmService = new GtmService()
    const virtualPageViewData = gtmService.createVirtualPageView(
      window.location.href,
      document.title
    )

    OldTagManager.push(virtualPageViewData)
  }

  useEffect(() => {
    handleVirtualPageViewGta()
  }, [dependencies])

  return { setDependencies }
}
